











































































import { Component, Vue, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { quanxianMixin } from "@/mixins/quanxianMixin";
import { listMixin } from "@/mixins/listMixin";
import MainHeader from "@/components/main-header/main-header.vue";
import { Search } from "@/request/schema";
@Component({
  components: {
    MainHeader,
  },
})
export default class Name extends mixins(listMixin, quanxianMixin) {
  private list: any = [];
  private goSearch() {
    this.getList();
  }
  private goDetail(item: any) {
    this.$router.push({
      path: "/main/mark/schema/folder",
      query: {
        data: JSON.stringify(item),
      },
    });
  }
  /**
   * @description 获取用户列表
   */
  getList() {
    if (!this.filters.search) {
      this.$message.warning("请输入搜索关键词");
      return;
    }
    const params: any = {
      params: {
        search: this.filters.search,
        //   current_page: this.filters.current_page,
        //   page_num: this.filters.page_count,
      },
    };
    localStorage.setItem("searchTypeFilter", JSON.stringify(this.filters));
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    Search(this, params, loading)
      .then((data: any) => {
        loading.close();
        // this.data = data;
        this.list = data.result;
        this.filters.total_count = data.total;
        // this.filters.total_page = data.total_page;
        if (this.list.length === 0) {
          this.ifShowEmpty = true;
        } else {
          this.ifShowEmpty = false;
        }
      })
      .catch(() => {
        loading.close();
      });
  }
  mounted() {
    const d = localStorage.getItem("searchTypeFilter") || "";
    if (this.$route.query.search) {
      this.filters.search = this.$route.query.search;
      localStorage.setItem("searchTypeFilter", JSON.stringify(this.filters));
    }
    if (d) {
      this.filters = JSON.parse(d);
    }
    this.getList();
  }
}
